<template>
  <div class=" h-full w-full pt-5 px-3">
    <div class="flex">
      <h1 class="text-xl text-left font-extrabold pl-4">
        Notifications
      </h1>
      <div class="w-7/12 mt-1 ml-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div class="flex my-6 px-4 justify-between">
      <Menu @click.stop class="w-10 h-10" has-border>
        <template v-slot:title>
          <Icon
            class-name="text-darkPurple h-full mx-auto self-center"
            size="s"
            icon-name="arrow_exchange"
          />
        </template>
        <template v-slot:dropdownTitle>
          SORT BY:
        </template>
        <div
          v-for="(option, index) in sortOptions"
          class="text-sm mx-2 px-2 pt-3 cursor-pointer"
          style="width: 180px"
          @click="handleSort(option.name)"
          :key="index"
        >
          {{ option.name }}
        </div>
      </Menu>
      <div class="flex ml-3 -mt-2 flex-1 w-auto">
        <SearchInput
          placeholder="Search"
          class="search"
          style="width : 300px; margin-top: 0.1rem;"
        />
            <Menu @click.stop class="w-max -ml-6">
              <template v-slot:title>
                <Icon
                class-name="text-darkPurple h-full mx-auto mt-2 self-center mr-2 cursor-pointer"
                size="xs"
                icon-name="sort_size"
                />
              </template>
              <div class="py-3 px-2 w-36">
                <div
                  class="flex flex-col py-1 px-1 mb-3 h-8 more cursor-pointer"
                >
                <p class="text-base font-bold uppercase">Status</p>

                <div class="h-1 border border-2"></div>
                </div>

                <div
                  class="flex py-1 px-1 mb-3 h-8 more cursor-pointer"
                  @click="filterNotifications('')"
                >
                <checkbox
                label-style="color:#333333"
                v-model="showAll"
                style="z-index: -1"
                checkbox-size="height:16px; width:16px; margin-top: 4px"
                />
                <p class="text-base">Show All</p>
                </div>

                <div
                  class="flex py-1 px-1 mb-3 h-8 more cursor-pointer"
                  @click="filterNotifications('read')"
                >
                <checkbox
                label-style="color:#333333"
                style="z-index: -1"
                v-model="read"
                checkbox-size="height:16px; width:16px; margin-top: 4px"
                />
                <p class="text-base">Read</p>
                </div>

                <div
                  class="flex py-1 px-1 mb-3 h-8 more cursor-pointer"
                  @click="filterNotifications('unread')"
                >
                <checkbox
                label-style="color:#333333"
                v-model="unRead"
                style="z-index: -1"
                checkbox-size="height:16px; width:16px; margin-top: 4px"
                />
                <p class="text-base">Unread</p>
                </div>
                </div>
            </Menu>
      </div>

      <Card class="w-auto h-10 ml-3 flex">
            <Menu left top="60" margin="0" class="p-0 mt">
              <template v-slot:title>
                <div
                class="flex cursor-pointer"
                style="width: 180px; padding: 5px 8px"
                >
                <icon icon-name="calendar" class="icon mt-1" />
                <div
                class="border-r"
                style="width: 1px; margin: 8px 8px; border-color: #321c3b"
                />
                <div
                class="text-darkPurple h-full"
                style="width: 100px; font-size: 16px; margin: 4px 6px"
                >
                Year to Date
              </div>
              <icon icon-name="caret-down" class="icon mt-1" />
            </div>
            </template>

              <div class="py-3 px-2 w-36">
                <div
                  @click="filterNotifications('today')"
                  class="flex py-1 px-1 mb-3 h-8 more cursor-pointer"
                >
                <p class="text-base">Today</p>
                </div>
                <div
                  @click="filterNotifications('thisWeek')"
                  class="flex py-1 px-1 mb-3 h-8 more cursor-pointer"
                >
                <p class="text-base">This Week</p>
                </div>
                <div
                  @click="filterNotifications('lastWeek')"
                  class="flex py-1 px-1 mb-3 h-8 more cursor-pointer"
                >
                <p class="text-base">Last Week</p>
                </div>
                <div
                  @click="filterNotifications('last30Days')"
                  class="flex py-1 px-1 mb-3 h-8 more cursor-pointer"
                >
                <p class="text-base">Last 30 Days</p>
                </div>

                <div
                  @click="openDate = true"
                  class="flex py-1 px-1 h-8 more cursor-pointer"
                >
                <p class="text-base text-flame">Custom</p>
                </div>

                </div>
            </Menu>
      </Card>

      <div class="h-10 mt-1 absolute" v-if="openDate" style="right: 10px; top: 23vh; z-index: 9">
        <date-range-picker
        :range="true"
        :editable="false"
        placeholder="Select Date"
        inline
         @input="getDateRange($event)"
        />
      </div>

      <Card class="w-10 h-10 flex ml-3 mr-4">
        <Icon
          class-name="text-darkPurple cursor-pointer mx-auto self-center"
          size="xs"
          @click.native="getAllNotifications('')"
          icon-name="undo"
        />
      </Card>
    </div>
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-if="!loading && notifications.length > 0" class="px-4">
      <sTable
        :headers="header"
        :items="notifications"
        :pagination-list="metaData"
        @page="handlePage($event)"
        @itemsPerPage="handleItemsPerPage($event)"
        page-sync
        :loading="false"
        aria-label="function table"
        id="printMe"
        class="w-full"
        style="width:100%"
        :has-number="false"
      >
        <template v-slot:item="{ item }">
          <span v-if="item.title">
            <div class="flex">
              <span :class="item.data.read_status ? 'text-romanSilver' : ''">
                {{ item.data.title }}
              </span>
            </div>
          </span>
          <span :class="item.data.read_status ? 'text-romanSilver' : ''" v-else-if="item.description">
            {{ item.data.description }}
          </span>
          <span class="wrapper" v-else-if="item._id">
            <Menu left top="0" margin="0" class="p-0 mt">
              <template v-slot:title>
                <icon icon-name="more_icon" size="xsm" class="" style="z-index: 0" />
              </template>
              <div class="py-3 px-2 w-40">
                <div
                  @click="item.data.read_status ? handleMarkAsUnRead(item.data._id) : handleMarkAsRead(item.data._id)"
                  class="flex px-1 mb-3 h-8 more cursor-pointer"
                >
                  <icon
                    icon-name="edit"
                    size="xs"
                    class="mt-1 mr-2 text-blueCrayola"
                  />
                  <p class="pt-2 text-sm">
                    {{
                      item.data.read_status ? "Mark as Unread" : "Mark as Read"
                    }}
                  </p>
                </div>
                <div
                  @click="confirmDelete(item.data._id)"
                  class="flex px-1 h-8 more cursor-pointer"
                >
                  <icon
                    icon-name="delete"
                    size="xs"
                    class="-mt-1 mr-2 text-flame"
                  />
                  <p class="pt-1 text-sm">
                    Delete
                  </p>
                </div>
              </div>
            </Menu>
          </span>
          <span class="wrapper" v-else-if="item.createdAt">
            <p>
              {{ $DATEFORMAT(new Date(item.data.createdAt), "dd/MM/yyyy") }}
            </p>
            <p>
              {{ $DATEFORMAT(new Date(item.data.createdAt), "hh:mm a") }}
            </p>
          </span>
        </template>
      </sTable>
    </div>
    <card
      class="w-full h-auto flex flex-col"
      style="align-items: center"
      v-if="!loading && notifications.length === 0"
    >
      <div class="w-5/12 h-auto mt-5 mb-5 flex" style="justify-content: center">
        <img src="@/assets/images/mail.png" alt="" />
      </div>
      <div class="w-8/12 h-auto text-center">
        <p class="mt-5 mb-5 tracking-wide">
          There are no notifications available right now.
        </p>
        <p class="my-5">
          <button @click="$router.go(-1)" class="flex m-auto items-center">
            <Icon
              size="xsm"
              icon-name="icon-arrow-left"
              class-name="mt-1 text-flame"
            />
            <p class="ml-3 mt-1 font-semibold text-flame">
              Go back
            </p>
          </button>
        </p>
      </div>
    </card>
    <Modal v-if="dialogDel">
      <Card class=" p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold  ml-4 my-5 text-center">
            Are you sure you want to delete this Notification?
          </p>
          <div class="flex ">
            <Button
              class="text-white" @click="deleteNotification"
              :background="appearance.buttonColor || defaultBTNColor"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn ml-4 border border-solid border-dynamicBackBtn"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DateRangePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Checkbox from "@scelloo/cloudenly-ui/src/components/checkbox";
import Menu from "@/components/Menu";
import Card from "@/components/Card";
import Modal from "@/components/Modal";
import Button from "@/components/Button";

export default {
  name: "Notification",
  components: {
    Breadcrumb,
    Checkbox,
    STable,
    Menu,
    Button,
    Modal,
    SearchInput,
    Card,
    DateRangePicker
  },
  data() {
    return {
      dialogDel: false,
      notificationId: "",
      startDate: "",
      endDate: "",
      loadingData: false,
      metaData: {},
      notifications: [],
      setDate: '',
      showAll: true,
      read: false,
      unRead: false,
      itemsPerPage: 50,
      openDate: false,
      numberOfPage: 1,
      loading: true,
      header: [
        { title: "", value: "title" },
        { title: "", value: "description" },
        { title: "", value: "createdAt", width: "15" },
        { title: "", value: "_id" }
      ],
      breadcrumbs: [
        {
          disabled: false,
          text: "Notifications",
          id: ""
        }
      ],
      sortOptions: [
        {
          name: "A to Z"
        },
        {
          name: "Z to A"
        },
        {
          name: "Newest First"
        }
      ]
    };
  },

  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  methods: {
    filterNotifications(query) {
      if (query === '') {
        this.showAll = true;
        this.read = false; this.unRead = false;
      }

      if (query === 'read') {
        this.read = true; this.showAll = false; this.unRead = false;
      }

      if (query === 'unread') {
        this.unRead = true; this.showAll = false; this.read = false;
      }

      this.loading = true;

      const payload = `?filter=${query}`;

      this.$_getNotifications(payload).then((response) => {
        this.loading = false;
        this.notifications = response.data.notifications;
        this.metaData = response.data.meta;
      });
    },

    getDateRange(value) {
      const splitDate = value.toString().split(',');
      this.startDate = this.formatDate(splitDate[0]);
      this.endDate = this.formatDate(splitDate[1]);
      this.openDate = false;

      this.filterNotifications(`custom&start=${this.startDate}&end=${this.endDate}`);
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getAllNotifications(`?filter=${pageNumber}${itemPage}`);
    },

    deleteNotification() {
      this.$_deleteNotification(this.notifitcationId)
        .then(() => {
          this.$toasted.success("Notification deleted", {
            duration: 3000
          });
          this.getAllNotifications('');
          this.dialogDel = false;
        })
        .catch(() => {
          this.dialogDel = false;
          this.$toasted.error("Notification not deleted", { duration: 2000 });
        });
    },
    confirmDelete(id) {
      this.notifitcationId = id;
      this.dialogDel = true;
    },
    handleMarkAsRead(id) {
      const payload = {
        userId: this.$AuthUser.id,
        ids: [id],
        app: "humanar"
      };
      this.$_markNotificationsAsRead(payload)
        .then(() => {
          this.getAllNotifications('');
          this.$toasted.success("Notification marked as read", {
            duration: 3000
          });
        })
        .catch(() =>
          this.$toasted.error("An error occurred", { duration: 2000 })
        );
    },

    handleMarkAsUnRead(id) {
      const payload = {
        userId: this.$AuthUser.id,
        ids: [id],
        app: "humanar"
      };
      this.$_markNotificationsAsUnRead(payload)
        .then(() => {
          this.getAllNotifications('');
          this.$toasted.success("Notification marked as unread", {
            duration: 3000
          });
        })
        .catch(() =>
          this.$toasted.error("An error occurred", { duration: 2000 })
        );
    },

    getAllNotifications(params) {
      this.loading = true;
      this.$_getNotifications(params).then((response) => {
        this.loading = false;
        this.notifications = response.data.notifications;
        this.metaData = response.data.meta;
      })
    }
  },
  mounted() {
    this.getAllNotifications("");
  }
};
</script>

<style lang="scss" scoped>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}

.wrapper {
  white-space: nowrap;
  overflow: hidden;
  width: 250px;
  text-overflow: ellipsis;
}
.status {
  width: 10px;
  height: 10px;
  flex: none;
  border-radius: 50%;
  &_unread {
    background: #13b56a;
  }
  &_read {
    background: #878e99;
  }
}
</style>
